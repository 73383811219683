<script>
import { email, required, minLength, sameAs } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { REGISTER, REMOVE_ERRORS } from "@/store/actions.type"
import OnboardingCard from '../../../registration/OnboardingCard.vue'
import OnboardingHeading from '../../../registration/OnboardingHeading.vue'

export default {
  name: "CreateAccount",

  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      agreeTermsAndConditions: false
    };
  },

  components: {
    OnboardingCard,
    OnboardingHeading
  },

  validations: {
    name: { required },
    email: { required, email },
    password: {
      required,
      minLength: minLength(8),
      valid: function(value) {
        const containsSpecial = /[#?!@$%^&*-]/.test(value)
        return containsSpecial
      }
    },
    confirmPassword: { required, sameAsPassword: sameAs('password') },
  },

  created() {
    this.$store.dispatch(`auth/${REMOVE_ERRORS}`);
  },

  computed: {
    ...mapGetters('auth', ['isLoading', 'errors']),
    checkNameErrors() {
      const nameErrors = [];
      if (!this.$v.name.$dirty) return nameErrors;
      !this.$v.name && nameErrors.push('Must be valid name');
      !this.$v.name.required && nameErrors.push('Name required');
      return nameErrors;
    },
    checkEmailErrors () {
      const emailErrors = [];
      if (!this.$v.email.$dirty) return emailErrors
      !this.$v.email.email && emailErrors.push('Enter a valid email address')
      !this.$v.email.required && emailErrors.push('Email is required')
      return emailErrors
    },
    checkPasswordErrors () {
      const passwordErrors = []
      if (!this.$v.password.$dirty) return passwordErrors
      !this.$v.password.required && passwordErrors.push('Password is required')
      if(!this.$v.password.minLength || !this.$v.password.valid) {
        passwordErrors.push('Must contain at least 8 characters and at least 1 special character')
      }
      return passwordErrors
    },
    checkConfirmPasswordErrors() {
      const confirmPasswordErrors = [];
      if (!this.$v.confirmPassword.$dirty) return confirmPasswordErrors;
      !this.$v.confirmPassword.required &&
      confirmPasswordErrors.push('Confirm password required');
      !this.$v.confirmPassword.sameAsPassword &&
      confirmPasswordErrors.push('Passwords do not match');
      return confirmPasswordErrors;
    },
    checkTermsErrors () {
      const termsErrors = []
      if (!this.$v.agreeTermsAndConditions.$dirty) return termsErrors
      !this.$v.agreeTermsAndConditions.sameAs && termsErrors.push('Please agree to terms and conditions')
      return termsErrors
    },
  },

  methods: {
    registerSubmit(name, email, password, confirmPassword) {
      this.$v.$touch();
      if (!this.$v.$invalid){
        this.$store.dispatch(`auth/${REGISTER}`, {
          name: name,
          email: email,
          password: password,
          password_confirmation: confirmPassword
        }).then(() => {
          this.$router.push({name: 'registration.woocommerce.select-store-type'})
        }).catch((error) => {
          console.log(error.data)
        });
      }
    }
  },
}
</script>

<template>
  <section class="onboarding v2">
    <OnboardingHeading heading="Register and create your account" />
    <p class="onboarding-text m-t-2">
      You'll use these details to login in the future
    </p>

    <section v-show="errors" class="onboarding-section m-t-3">
      <v-alert v-for="(value, key) in errors" v-bind:key="key" class="error rounded m-b-0" transition="fade-transition" dismissible>
        <span class="white--text">{{ value }}</span>
      </v-alert>
    </section>

    <OnboardingCard>
      <v-text-field
        :error-messages="checkNameErrors"
        @change="$v.name.$touch()"
        class="onboarding-input"
        dense
        label="Your name"
        outlined
        type="text"
        v-model="name" />

      <v-text-field
        :error-messages="checkEmailErrors"
        @change="$v.email.$touch()"
        class="onboarding-input m-y-2"
        clearable
        dense
        label="Email address"
        outlined
        type="email"
        v-model="email" />

      <v-text-field
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :error-messages="checkPasswordErrors"
        :type="showPassword ? 'text' : 'password'"
        @change="$v.password.$touch()"
        @click:append="showPassword = !showPassword"
        class="onboarding-input password m-y-2"
        clearable
        dense
        hint="Must contain at least 8 characters and at least 1 special character"
        label="Password"
        outlined
        persistent-hint
        v-model="password" />

      <v-text-field
        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :error-messages="checkConfirmPasswordErrors"
        :type="showConfirmPassword ? 'text' : 'password'"
        @change="$v.confirmPassword.$touch()"
        @click:append="showConfirmPassword = !showConfirmPassword"
        class="onboarding-input password m-y-2"
        clearable
        dense
        label="Confirm password"
        outlined
        v-model="confirmPassword" />

      <v-btn
        @click="registerSubmit(name,email,password,confirmPassword)"
        block
        class="onboarding-btn m-t-2"
        color="primary"
        elevation="0">
        Create Syncio account
      </v-btn>
    </OnboardingCard>

    <aside class="terms text-center m-b-3 m-t-1">
      <p>By proceeding with the registration, you are agreeing to the</p>
      <p>
        Syncio
        <a href="https://www.syncio.co/terms-and-conditions" target="_blank" class="link link-underline hovered d-inline-block">
          terms and conditions
        </a>
        and
        <a href="https://www.syncio.co/privacy-policy" target="_blank" class="link link-underline hovered d-inline-block">
          privacy policy
        </a>
      </p>
    </aside>

    <aside class="d-flex justify-center m-b-2">
      <router-link to="/login" class="register-btn">
        Already have an account? Login here
      </router-link>
    </aside>
  </section>
</template>
